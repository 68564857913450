import React from "react";

import {mobXStore} from "../../../../SEASONALGURU/store";

import {Layout, Button, Row, Col} from "antd";
import {RiMenuFill} from "react-icons/ri";
import HeaderLanguages from "./HeaderLanguages";
import HeaderUser from "./HeaderUser";
import HeaderNotifications from "./HeaderNotifications";
// import HeaderText from "./HeaderText";

const {Header} = Layout;

export default function MenuHeader(props) {
    const {setVisible} = props;

    // Focus
    // const inputFocusRef = useRef(null);
    // const inputFocusProp = {
    //   ref: inputFocusRef,
    // };

    // Mobile Sidebar
    const showDrawer = () => {
        setVisible(true);
    };

    // Children
    const headerChildren = () => {
        return (
            <Row
                className="hp-w-100 hp-position-relative"
                align="middle"
                justify="space-between"
            >
                <Col className="hp-mobile-sidebar-button hp-mr-24">
                    <Button
                        className="hp-mobile-sidebar-button"
                        type="text"
                        onClick={showDrawer}
                        icon={
                            <RiMenuFill
                                size={24}
                                className="remix-icon hp-text-color-black-80 hp-text-color-dark-30"
                            />
                        }
                    />
                </Col>

                <Col
                    flex="1"
                    style={{display: 'block'}}
                    // className={`hp-pr-md-0 hp-pr-16 hp-header-search`}
                >
                </Col>

                <Col>
                    <Row align="middle">
                        <HeaderLanguages/>

                        <HeaderNotifications/>

                        <HeaderUser/>
                    </Row>
                </Col>
            </Row>
        )
    }

    return (
        <Header>
            <Row justify="center" className="hp-w-100">
                {
                    mobXStore.getKey('contentWidth') === "full" && (
                        <Col span={24}>
                            {headerChildren()}
                        </Col>
                    )
                }
                {
                    mobXStore.getKey('contentWidth') === "boxed" && (
                        <Col xxl={20} xl={22} span={24}>
                            {headerChildren()}
                        </Col>
                    )
                }
            </Row>
        </Header>
    );
}