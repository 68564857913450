import React, {Suspense, useEffect} from "react";

// Router
import {
    BrowserRouter,
    Route,
    Switch,
} from "react-router-dom";

// Routes
import {Routes} from "./routes";

import {mobXStore, observer} from "../store";

// Layouts
import VerticalLayout from "../../YODA/layout/VerticalLayout";
import FullLayout from "../../YODA/layout/FullLayout";

// import AdSense from 'react-adsense';

// HOC
import RouteWithAuth from "../hoc/RouteWithAuth";

function Router() {
    let isLogin = mobXStore.getKey('login');

    // Dark Mode
    useEffect(() => {
        document.querySelector("body").classList.add(mobXStore.getKey('theme'));
    }, [mobXStore.store.get('theme')]);

    // checkLogin
    useEffect(() => {
        isLogin = mobXStore.getKey('login')
        // console.log('LOGIN', isLogin);
    }, [mobXStore.store.get('login')]);

    // RTL
    useEffect(() => {
        if (mobXStore.getKey('direction') === "ltr") {
            document.querySelector("html").setAttribute("dir", "ltr");
        } else if (mobXStore.getKey('direction') === "rtl") {
            document.querySelector("html").setAttribute("dir", "rtl");
        }
    }, [mobXStore.store.get('direction')]);

    // Default Layout
    // const DefaultLayout = mobXStore.getKey('layout'); // FullLayout or VerticalLayout

    // All the available layouts
    const Layouts = {VerticalLayout, FullLayout};

    // Return Filtered Array of Routes & Paths
    const LayoutRoutesAndPaths = (layout) => {
        const LayoutRoutes = [];
        const LayoutPaths = [];
        if (Routes) {
            // Checks if Route layout or Default layout matches current layout
            Routes.filter(route => (route.layout === layout) && (
                LayoutRoutes.push(route), LayoutPaths.push(route.path)
            ));
        }
        return {LayoutRoutes, LayoutPaths};
    };

    // Return Route to Render
    const ResolveRoutes = () => {

        return Object.keys(Layouts).map((layout, index) => {

            const {LayoutRoutes, LayoutPaths} = LayoutRoutesAndPaths(layout);

            const LayoutTag = Layouts[layout];

            return (
                <Route path={LayoutPaths} key={index}>
                    <LayoutTag>
                        <Switch>
                            {LayoutRoutes.map((route) => {
                                return (
                                    <RouteWithAuth
                                        key={route.path}
                                        path={route.path}
                                        exact={route.exact === true}
                                        isPublic={route.public}
                                        isLogin={isLogin}
                                        redirectPath="/login"
                                    >
                                        <Suspense fallback={null}>
                                            {React.createElement(route.component)}
                                        </Suspense>
                                    </RouteWithAuth>
                                )
                            })}
                        </Switch>
                    </LayoutTag>
                </Route>
            );
        });
    };

    return (
        <BrowserRouter>
            <Switch>
                {ResolveRoutes()}
            </Switch>
        </BrowserRouter>
    );
}

export default observer(Router);