import {makeObservable, observable, action, computed, autorun, toJS} from "mobx";

import INITIAL_STATE from './InitialState';

require('dotenv').config();

class MobXStore {
    store = observable.map();
    debug = false;
    name = "s_r_g_s_c_j_s_g_r_u"; // Name of browser local storage

    constructor() {
        makeObservable(this, {
            store: observable,
            setKey: action,
            set: action,
            deleteKey: action,
            delete: action,
            getKey: action,
            get: computed,
            setDebug: action,
            keyExists: action,
            saveStore: action
        });

        // this.setDebug(process.env.REACT_APP_MOBX_DEBUG);

        // Load state from browser local storage
        if (localStorage.getItem(this.name) === null) {
            this.store = observable.map(INITIAL_STATE);
        } else {
            this.loadStore();
        }

        autorun(() => this.saveStore());
    }

    // Set DEBUG mode
    setDebug(debug) {
        this.debug = debug;
    }

    // Load state from browser local storage
    loadStore = () => {
        if (this.debug) {
            console.log('load store');
        }
        try {
            const cache = JSON.parse(localStorage.getItem(this.name));
            Object.keys(cache).forEach(key => {
                this.setKey(cache[key][0], cache[key][1]);
            });
        } catch (e) {
            if (this.debug) {
                console.log(e.message);
            }
        }
    }

    // Save state to browser local storage
    saveStore() {
        if (this.debug) {
            console.log('save store');
        }
        localStorage.setItem(this.name, JSON.stringify(Array.from(toJS(this.store).entries())));
    }

    // Set store value buy Key
    setKey = (key, value) => {
        if (this.debug) {
            console.log('> MOBX: setKey', key, value);
        }
        this.store.set(key, value);
        this.saveStore();
    }

    // Check is key exists
    keyExists = (key) => {
        if (this.debug) {
            console.log('? MOBX: keyExists', key, this.store.has(key));
        }
        return this.store.has(key);
    }

    // set NEW store state
    set = (value) => {
        if (this.debug) {
            console.log('> MOBX: SET', value);
        }
        this.store = observable.map(value);
        this.saveStore();
    }

    // delete store value by Key
    deleteKey = (key) => {
        if (this.store.has(key)) {
            if (this.debug) {
                console.log('! MOBX: deleteKey', key);
            }
            this.store.delete(key);
            this.saveStore();
        } else {
            console.log('! MOBX: deleteKey', key, 'Not Found!');
        }
    }

    // delete store state - ERASE ALL
    delete = () => {
        if (this.debug) {
            console.log('! MOBX: DELETE');
        }
        this.store = observable.map({});
        this.saveStore();
    }

    // get store value by Key
    getKey = (key) => {
        if (this.store.has(key)) {
            if (this.debug) {
                console.log('< MOBX: getKey', key, this.store.get(key));
            }
            return this.store.get(key);
        } else {
            if (this.debug) {
                console.log('< MOBX: getKey NOT FOUND', key);
            }
            return '';
        }
    }

    // get store state
    get get() {
        if (this.debug) {
            console.log('< MOBX: GET', this.store);
        }
        return this.store;
    }
}

export default new MobXStore();
