import React, { useState, createElement, useEffect } from "react";

import {mobXStore, observer} from "../../../../SEASONALGURU/store";

import {
    Layout,
    Button,
    Row,
    Col,
} from "antd";
import {
    RiMenuFoldLine,
    RiMenuUnfoldLine,
} from "react-icons/ri";

import MenuLogo from "../../../../SEASONALGURU/view/components/MenuLogo/MenuLogo";
import MenuItem from "./item";
import MenuMobile from "./mobile";

const { Sider } = Layout;

function Sidebar (props) {
    const {visible, setVisible} = props;

    // Collapsed
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {

        if (mobXStore.getKey('sidebarCollapsed')) {
            setCollapsed(true);
        } else {
            setCollapsed(false);
        }
    }, [mobXStore.store.get('sidebarCollapsed')])

    // // Location
    // const location = useLocation();
    // // const {pathname} = location;

    // Mobile Sidebar
    const onClose = () => {
        setVisible(false);
    };

    // Menu
    function toggle() {
        setCollapsed(!collapsed);
    }

    const trigger = createElement(
        collapsed ? RiMenuUnfoldLine : RiMenuFoldLine,
        {
            className: "trigger",
            onClick: toggle,
        }
    );

    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={256}
            className="hp-sidebar hp-bg-color-black-0 hp-bg-color-dark-100"
        >
            <Row className="hp-mr-12 hp-ml-24 hp-mt-24" align="bottom" justify="space-between">
                <Col>
                    {collapsed === false ? <MenuLogo onClose={onClose}/> : ""}
                </Col>

                {
                    mobXStore.getKey('sidebarCollapseButton') && (
                        <Col className="hp-pr-0">
                            <Button
                                icon={trigger}
                                type="text"
                                className="hp-float-right hp-text-color-dark-0"
                            ></Button>
                        </Col>
                    )
                }

                {/*{collapsed !== false && (*/}
                {/*    <Col className="hp-mt-8">*/}
                {/*        <Link*/}
                {/*            to="/"*/}
                {/*            onClick={onClose}*/}
                {/*        >*/}
                {/*            <img className="hp-MenuLogo" src={logoSmall} alt="MenuLogo"/>*/}
                {/*        </Link>*/}
                {/*    </Col>*/}
                {/*)}*/}
            </Row>

            <MenuItem onClose={onClose}/>

            {/*<MenuFooter onClose={onClose} collapsed={collapsed}/>*/}

            <MenuMobile onClose={onClose} visible={visible}/>
        </Sider>
    );
}

// export default function Sidebar;
export default observer(Sidebar);