import ReactGA from "react-ga4";
import MobXStore from "../SEASONALGURU/store/MobXStore";
import moment from "moment/moment";

ReactGA.initialize(process.env.REACT_APP_GA4_ID);

const GA_customEventTrack = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    })
}

const GA4_Garik_Conversion = () => GA_customEventTrack("garik_conversion", "garik_conversion", symbol);

const GA4_PageView = () => {
    ReactGA.send("pageview");
    conversions();
}

const GA4_PageViewUrl = (url) => {
    ReactGA.send({
        hitType: "pageview",
        page: url
    });
    conversions();
}

const GA4_LoginNative = () => ReactGA.event("login", {method: "Login"});
const GA4_Register = () => GA_customEventTrack("user", 'register', 'register');
const GA4_Login = () => GA_customEventTrack("user", 'login', 'login');
const GA4_Logout = () => GA_customEventTrack("user", 'logout', 'logout');

const GA4_gotoHome = () => GA_customEventTrack("page_change", "page_home", '');

const GA4_gotoGuruTrades = () => GA_customEventTrack("page_change", "page_guru_trades", '');

// const GA4_openTermsPage = () => GA_customEventTrack("page_change", "page_terms", '');
const GA4_openTermsPage = () => {
};

// const GA4_openRiskPage = () => GA_customEventTrack("page_change", "page_risk", '');
const GA4_openRiskPage = () => {
};

// const GA4_openContactPage = () => GA_customEventTrack("page_change", "page_contact", '');
const GA4_openContactPage = () => {
};

const GA4_gotoScreener = () => GA_customEventTrack("page_change", "page_screener", '');
const GA4_gotoHowTo = () => GA_customEventTrack("page_change", "page_howto", '');
const GA4_gotoNews = () => GA_customEventTrack("page_change", "page_news", '');

// const GA4_openAboutUsPage = () => GA_customEventTrack("page_change", "page_about_us", '');
const GA4_openAboutUsPage = () => {
};

const GA4_readNews = () => GA_customEventTrack("page_change", "page_news", '');

const GA4_searchMain = (symbol) => GA_customEventTrack("search", "search_main", symbol);

const GA4_infoChangeTab = (symbol) => GA_customEventTrack("info", "info_change_tab", symbol);

const GA4_indexChangeTab = (symbol) => GA_customEventTrack("info", "index_change_tab", symbol);

const GA4_searchScreener = (symbol) => GA_customEventTrack("search", "search_screener", symbol);

const GA4_selectContent = (object_name) => ReactGA.event("select_content", {content_type: object_name});

const GA4_selectPattern = (symbol) => GA_customEventTrack("select_content", "select_pattern", symbol);

const GA4_openChartOnHomePage = (symbol) => GA_customEventTrack("historical_chart", "open_chart_on_search_page", symbol);

const GA4_openChartOnScreenerPage = (symbol) => GA_customEventTrack("historical_chart", "open_chart_on_screener_page", symbol);

const GA4_openChartOnAnalyzingPage = (symbol) => GA_customEventTrack("historical_chart", "open_chart_on_analyzing_page", symbol);

const GA4_shareUrl = (url) => GA_customEventTrack("share", "copy_url_to_clipboard", url);

const GA4_changeEquityMode = (checked) => GA_customEventTrack("select_content", "change_equity_mode", checked);

const GA4_clickOnSearchedPattern = (symbol) => GA_customEventTrack("patterns", "click_on_searched_pattern", symbol);

const GA4_clickOnNearestPattern = (symbol) => GA_customEventTrack("patterns", "click_on_nearest_pattern", symbol);
const GA4_clickOnLongTabTable = (symbol) => GA_customEventTrack("patterns", "click_on_long_tab_table", symbol);
const GA4_clickOnShortTabTable = (symbol) => GA_customEventTrack("patterns", "click_on_short_tab_table", symbol);

const GA4_clickOnStartAnalyzingDirect = (symbol) => GA_customEventTrack("patterns", "click_on_start_analyzing", symbol);

const GA4_clickOnScreenerPattern = (symbol) => GA_customEventTrack("patterns", "click_on_screener_pattern", symbol);

const GA4_changePeriod = (symbol) => GA_customEventTrack("patterns", "change_period", symbol);

const GA4_changeDirection = (symbol) => GA_customEventTrack("patterns", "change_direction", symbol);

const GA4_Page_2 = () => GA_customEventTrack("conversion", "Page_2", 'Page_2');
const GA4_Page_3 = () => GA_customEventTrack("conversion", "Page_3", 'Page_3');
const GA4_Page_4 = () => GA_customEventTrack("conversion", "Page_4", 'Page_4');
const GA4_Page_5 = () => GA_customEventTrack("conversion", "Page_5", 'Page_5');

const GA4_Page_6 = () => GA_customEventTrack("conversion", "Page_6", 'Page_6');
const GA4_Time_1min = () => GA_customEventTrack("conversion", "Time_1min", 'Time_1min');
const GA4_Time_2min = () => GA_customEventTrack("conversion", "Time_2min", 'Time_2min');
const GA4_Time_3min = () => GA_customEventTrack("conversion", "Time_3min", 'Time_3min');
const GA4_Time_4min = () => GA_customEventTrack("conversion", "Time_4min", 'Time_4min');
const GA4_Time_5min = () => GA_customEventTrack("conversion", "Time_5min", 'Time_5min');

// GA4 Conversions send
const conversions = () => {

    const emptyConfig = {
        pv2: false,
        pv3: false,
        pv4: false,
        pv5: false,
        pv6: false,
        time1: false,
        time2: false,
        time3: false,
        time4: false,
        time5: false,
    };

    let config = MobXStore.getKey('conv_config') || emptyConfig;

    MobXStore.setKey('conv_config', config);

    let sessionPageView = MobXStore.getKey('pv') || 0;
    let sessionStartTime = MobXStore.getKey('st') || 0;
    let sessionLengthTime = moment().unix() - sessionStartTime || 0;
    let sessionLastUpdate = MobXStore.getKey('lu') || 0;
    sessionLengthTime = Math.floor(sessionLengthTime / 60);

    if (sessionPageView === 0 || sessionStartTime === 0 || sessionLastUpdate === 0) {
        // first page view
        MobXStore.setKey('ttl', moment());
        MobXStore.setKey('st', moment().unix());
        MobXStore.setKey('lu', moment().unix());
        sessionPageView = 0;
        config = emptyConfig;
        MobXStore.setKey('conv_config', config);
    } else if (moment(MobXStore.getKey('ttl')).format('D') !== moment().format('D') || sessionLastUpdate < moment().unix() - 30 * 60) {
        // session expired
        MobXStore.setKey('ttl', moment());
        MobXStore.setKey('st', moment().unix());
        MobXStore.setKey('lu', moment().unix());
        sessionPageView = 0;
        sessionLengthTime = 0;
        config = emptyConfig;
        MobXStore.setKey('conv_config', config);
    }

    // Update session time
    MobXStore.setKey('lu', moment().unix());

    sessionPageView++;
    MobXStore.setKey('pv', sessionPageView);

    // console.log('sessionPageView', sessionPageView);
    // console.log('sessionLengthTime', moment().unix() - sessionStartTime, sessionLengthTime);

// Conversions
    switch (sessionPageView) {
        case 2:
            if (!config.pv2) {
                GA4_Page_2();
                config.pv2 = true;
            }
            break;
        case 3:
            if (!config.pv3) {
                GA4_Page_3();
                config.pv3 = true;
            }
            break;
        case 4:
            if (!config.pv4) {
                GA4_Page_4();
                config.pv4 = true;
            }
            break;
        case 5:
            // Send conversion to Garik
            if (!config.pv5) {
                window.gtag('event', 'conversion', {'send_to': process.env.REACT_APP_GA4_GARIK_ID});
                GA4_Page_5();
                config.pv5 = true;
            }
            break;
        case 6:
            if (!config.pv6) {
                GA4_Page_6();
                config.pv6 = true;
            }
            break;
        default:
            break;
    }

    switch (sessionLengthTime) {
        case 1:
            if (!config.time1) {
                GA4_Time_1min();
                config.time1 = true;
            }
            break;
        case 2:
            if (!config.time2) {
                GA4_Time_2min();
                config.time2 = true;
            }
            break;
        case 3:
            if (!config.time3) {
                GA4_Time_3min();
                config.time3 = true;
            }
            break;
        case 4:
            if (!config.time4) {
                GA4_Time_4min();
                config.time4 = true;
            }
            break;
        case 5:
            if (!config.time5) {
                GA4_Time_5min();
                config.time5 = true;
            }
            break;
        default:
            break;
    }

    MobXStore.setKey('conv_config', config);
}

export {
    GA_customEventTrack,
    GA4_Register,
    GA4_Login,
    GA4_LoginNative,
    GA4_Logout,
    GA4_searchMain,
    GA4_searchScreener,
    GA4_selectContent,
    GA4_shareUrl,
    GA4_clickOnNearestPattern,
    GA4_clickOnScreenerPattern,
    GA4_clickOnSearchedPattern,
    GA4_clickOnStartAnalyzingDirect,
    GA4_clickOnLongTabTable,
    GA4_clickOnShortTabTable,
    GA4_changeDirection,
    GA4_changePeriod,
    GA4_gotoGuruTrades,
    GA4_gotoHome,
    GA4_gotoScreener,
    GA4_gotoHowTo,
    GA4_PageView,
    GA4_PageViewUrl,
    GA4_gotoNews,
    GA4_openTermsPage,
    GA4_openRiskPage,
    GA4_openContactPage,
    GA4_openAboutUsPage,
    GA4_selectPattern,
    GA4_changeEquityMode,
    GA4_openChartOnHomePage,
    GA4_openChartOnScreenerPage,
    GA4_openChartOnAnalyzingPage,
    GA4_readNews,
    GA4_Garik_Conversion,
    GA4_Page_2,
    GA4_Page_3,
    GA4_Page_4,
    GA4_Page_5,
    GA4_Page_6,
    GA4_Time_1min,
    GA4_Time_2min,
    GA4_Time_3min,
    GA4_Time_4min,
    GA4_Time_5min,
    GA4_infoChangeTab,
    GA4_indexChangeTab
}