import React, {useEffect, useState} from "react";

import {mobXStore, observer} from "../../../../SEASONALGURU/store";

import {Menu, Avatar, Card, Col} from "antd";

function HeaderLanguages() {
    const language = mobXStore.getKey('language');
    const [activeLanguage, setActiveLanguage] = useState(language)
    const languages = ['en', 'tr', 'fr', 'es'];

    useEffect(() => {
        setActiveLanguage(language);
    }, [mobXStore.store.get('language')]);

    return (
        <Col className="hp-languages hp-mr-sm-4 hp-mr-8">
            <div className="hp-d-flex-center">
                <Avatar size={24} src={require(`./../../../../assets/images/languages/${activeLanguage}.svg`).default}/>
                <span
                    className="hp-languages-text hp-text-color-black-60 hp-text-uppercase hp-ml-10">{activeLanguage}</span>
            </div>

            <div className="hp-languages-list">
                <Card className="hp-border-color-black-40">
                    <Menu>
                        {
                            languages.map((item, index) => (
                                item !== activeLanguage && (
                                    <Menu.Item key={index} onClick={() => mobXStore.setKey('language', item)}>
                                        <div className="hp-d-flex-center">
                                            <Avatar size={24}
                                                    src={require(`./../../../../assets/images/${item}.svg`).default}/>
                                            <span
                                                className="hp-languages-text hp-text-color-black-60 hp-text-uppercase hp-ml-4">{item}</span>
                                        </div>
                                    </Menu.Item>
                                )
                            ))
                        }
                    </Menu>
                </Card>
            </div>
        </Col>
    );
};

export default observer(HeaderLanguages);