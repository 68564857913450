import themeConfig from "../../YODA/configs/themeConfig";

const INITIAL_STATE = {
    theme: themeConfig.theme,
    contentWidth: themeConfig.contentWidth,
    sidebarCollapsed: themeConfig.sidebarCollapsed,
    sidebarCollapseButton: themeConfig.sidebarCollapseButton,
    layout: themeConfig.layout,
    navigationFull: themeConfig.navigationFull,
    navigationBg: themeConfig.navigationBg,
    direction: themeConfig.direction,
    language: themeConfig.language,
    show_ads: true,
    uid: 0,
    token: '',
    login: false,
    active: false,
    loading: false
};

export default INITIAL_STATE;
