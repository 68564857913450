import React from "react";
import logoImage from "../../../../assets/logo/seasonal-guru-logo.png";
import {Image} from "antd";

export default function MenuLogo() {
    return (
        <>
            <Image
                preview={false}
                src={logoImage}
                alt="SeasonalGuru.com"
                style={{width: 150}}
            />
        </>
    );
}