import React, { useState, useEffect } from "react";

import {
    useHistory
} from "react-router-dom";

import { Layout, Row, Col } from "antd";

import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import MenuFooter from "./components/footer";
import ScrollTop from "./components/scroll-to-top";
import {mobXStore} from "../../SEASONALGURU/store";

const { Content } = Layout;

export default function VerticalLayout(props) {
    const { children } = props;

    const history = useHistory();

    const [visible, setVisible] = useState(false);

    // checkLogin
    useEffect(() => {
        if(!mobXStore.getKey('login')) history.push('/');
    }, [mobXStore.store.get('login')]);


    return (
        <Layout className="hp-app-layout">
            <Sidebar visible={visible} setVisible={setVisible} />

            <Layout className="hp-bg-color-dark-90">
                <MenuHeader setVisible={setVisible} />

                <Content className="hp-content-main">
                    <Row justify="center">
                        {
                            mobXStore.getKey('contentWidth') === "full" && (
                                <Col span={24}>
                                    {children}
                                </Col>
                            )
                        }

                        {
                            mobXStore.getKey('contentWidth') === "boxed" && (
                                <Col xxl={20} xl={22} span={24}>
                                    {children}
                                </Col>
                            )
                        }
                    </Row>
                </Content>

                <MenuFooter />
            </Layout>

            <ScrollTop />
        </Layout>
    );
};