import React, {Suspense} from "react";

import {BrowserRouter} from "react-router-dom";
import ReactDOM from "react-dom";

import "./assets/icons/remixicon.css";
import "./assets/less/yoda-theme.less";

import App from "./App";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

ReactDOM.render(
    <Suspense fallback="loading">
        <BrowserRouter>
            <DevSupport ComponentPreviews={ComponentPreviews}
                        useInitialHook={useInitial}
            >
                <App/>
            </DevSupport>
        </BrowserRouter>
    </Suspense>,
    document.getElementById("root")
);