import React, {useEffect, Suspense, useState} from 'react';

import {ConfigProvider, Image, Modal} from 'antd';
import {IntlProvider} from "react-intl";

import {mobXStore, observer} from "./SEASONALGURU/store";
import AppLocale from './YODA/languages';

import Router from "./SEASONALGURU/router/Router";
import SpinLoader from "./SEASONALGURU/view/components/SpinLoader";

function App() {
    // Lang
    let currentAppLocale;
    currentAppLocale = AppLocale[mobXStore.getKey('language')];
    
    useEffect(() => {
        document.querySelector("html").setAttribute("lang", mobXStore.getKey('language'));
        currentAppLocale = AppLocale[mobXStore.getKey('language')];
    }, [mobXStore.store.get('language')]);

    const direction = mobXStore.getKey('direction');
    return (
        <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
            <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                <Suspense fallback={<SpinLoader loading/>}>
                    <Router/>
                </Suspense>
            </IntlProvider>
        </ConfigProvider>
    );
}

export default observer(App);