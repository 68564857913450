import React from 'react';
import {Spin} from "antd";
import s from "../../../../assets/less/components/spinloader/index.less";

function SpinLoader(props) {
    const {tip, loading} = props;
    if (!loading) {
        return (
            <>
                {props.children}
            </>
        );
    }

    return (
        <div className={s.loader_wrap}>
            <Spin size="large" tip={tip}>
                {props.children}
            </Spin>
        </div>
    );
}

export default SpinLoader;