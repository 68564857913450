import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {GA4_PageView} from "../../GA4";

const RouteWithAuth = ({isPublic, isLogin, redirectPath = '/login', children, ...rest}) => {

    GA4_PageView();

    return (
        <Route
            {...rest}
            render={() => {
                return (isPublic || isLogin) ? (
                    children
                ) : (
                    <Redirect to={redirectPath}/>
                );
            }}
        />
    );
}

export default RouteWithAuth;