import {lazy} from "react";

const PagesRoutes = [
    {
        name: "Test",
        path: "/test/",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/TestIndexPage/TestIndexPage")),
        layout: "FullLayout",
    },
    {
        name: "Home",
        path: "/",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/IndexPage/IndexPage")),
        layout: "FullLayout",
    },
    {
        name: "ScreenerPage",
        path: "/screener/:query?/",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/ScreenerPage/ScreenerPage")),
        layout: "FullLayout",
    },
    {
        name: "News",
        path: "/news",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/NewsPage/NewsPage")),
        layout: "FullLayout",
    },
    {
        name: "Guru Trades",
        path: "/guru_trades",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/GuruTradesPage/GuruTradesPage")),
        layout: "FullLayout",
    },
    {
        name: "Home",
        path: "/search/:symbol_p/:info_p?/",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/InfoPage/InfoPage")),
        layout: "FullLayout",
    },
    {
        name: "HowToOld",
        path: "/howto_old/",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/HowToPage_1/HowToPage")),
        layout: "FullLayout",
    },
    {
        name: "HowTo",
        path: "/howto/",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/HowToPage/HowToPage")),
        layout: "FullLayout",
    },
    {
        name: "Panel Home",
        path: "/panel/:symbol_p/:startdate_p/:enddate_p/:direction/",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/SeasonalControlPanelPage/SeasonalControlPanelPage")),
        layout: "FullLayout",
    },
    {
        name: "Panel Home",
        path: "/app/:symbol_p/:startdate_p/:enddate_p/:direction/",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/SeasonalControlPanelPage/SeasonalControlPanelPage")),
        layout: "FullLayout",
    },
    {
        name: "Maintenance page",
        path: "/maintenance",
        exact: true,
        public: true,
        component: lazy(() => import("../../../YODA/view/pages/maintenance")),
        layout: "FullLayout",
    },
    {
        name: "Contact page",
        path: "/contact",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/ContactPage/ContactPage")),
        layout: "FullLayout",
    },
    {
        name: "About Us page",
        path: "/aboutus",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/AboutPage/AboutPage")),
        layout: "FullLayout",
    },
    {
        name: "Login page",
        path: "/login",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/authentication/login")),
        layout: "FullLayout",
    },
    {
        name: "Register page",
        path: "/register",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/authentication/register")),
        layout: "FullLayout",
    },
    {
        name: "UserPatternsPage page",
        path: "/user_patterns",
        exact: true,
        public: false,
        component: lazy(() => import("../../view/pages/UserPatternsPage/UserPatternsPage")),
        layout: "FullLayout",
    },
    {
        name: "UserGroupsPage page",
        path: "/user_groups",
        exact: true,
        public: false,
        component: lazy(() => import("../../view/pages/UserGroupsPage/UserGroupsPage")),
        layout: "FullLayout",
    },
    { // ОБЯЗАТЕЛЬНО ДОЛЖЕН БЫТЬ ПРЕДПОСЛЕДНИМ ПЕРЕД 404 !!!
        name: "Home",
        path: "/:today_master_tab_p?/:today_tab_p?/",
        exact: true,
        public: true,
        component: lazy(() => import("../../view/pages/IndexPage/IndexPage")),
        layout: "FullLayout",
    },
    { // THIS IS IMPORTANT FOR ROUTING
        name: "Error 404 page",
        path: "*",
        exact: false,
        public: true,
        component: lazy(() => import("../../../YODA/view/pages/errors/404")),
        layout: "FullLayout",
    },
];

export default PagesRoutes;